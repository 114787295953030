import { Maybe } from "@/types"
import cookieBasedStore from "./cookie"
import { Store } from "./types"

let _store = cookieBasedStore()
let doNotTrack = false

const dntStore = {
  getCustomerId: () => undefined,
  setCustomerId: () => _store.setCustomerId(undefined)
}

const store = () => (doNotTrack ? dntStore : _store)

const visits: Visits = {
  getCustomerId: () => store().getCustomerId(),
  setCustomerId: (id: string) => store().setCustomerId(id),
  isDoNotTrack: () => doNotTrack,
  setDoNotTrack: (dnt: boolean) => (doNotTrack = dnt),
  setStore: (s: Store) => (_store = s),
  setCustomerIdentifierService: (s: Store) => (_store = s)
}

export default visits

// explicitly defined to avoid inlining in typedoc
export interface Visits {
  getCustomerId(): Maybe<string>
  setCustomerId(id: string | undefined): void
  isDoNotTrack(): boolean
  setDoNotTrack(dnt: boolean): boolean
  setStore(s: Store): Store
  // @deprecated
  setCustomerIdentifierService(s: Store): Store
}
