import visit from "@/core/visit"
import ev1state from "@/core/ev1/state"

const customerIdFn = ev1state("customerId")

export default function getCustomerId(): Promise<string | undefined> {
  if (visit.isDoNotTrack()) {
    return Promise.resolve(undefined)
  }
  const customerId = visit.getCustomerId()
  if (customerId) {
    return Promise.resolve(customerId)
  } else {
    return customerIdFn()
  }
}
