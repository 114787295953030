import cookie from "@/core/cookie"
import { Store } from "./types"

/**
 * Default visit identifier storage that stored the customer-identifier in a
 * 2c.cId cookie.
 */
export default function cookieBasedStore(): Store {
  const key = "2c.cId"
  return {
    getCustomerId: () => cookie.get(key) || undefined,
    setCustomerId: (id: string | undefined) => cookie.set(key, id ?? null)
  }
}
